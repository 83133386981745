import React, { useEffect, useState } from "react";
import { Box, Paper, Typography, Button, CircularProgress, IconButton } from "@mui/material";
import Table from 'react-bootstrap/Table';
import axios from "axios";
import Cookies from "js-cookie";
import DownloadDocxButton from "../components/DownloadDocx";
import DownloadDocxIcon from "../components/DownloadDocxIcon";

import { API, SaveQuestionnaire, UpdateQuestionnaire } from "../classes/config/controller";
import { formatDate } from "../classes/GlobalFunctions";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DifferenceIcon from '@mui/icons-material/Difference';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import { ActionButton } from "../components/ActionButton";
import { GenerateDocument } from "../classes/GenerateDocx";
import { v4 as uuidv4 } from 'uuid';
import AddContentModal from "../components/AddContentModal";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

export const SavedDocuments = ()=>{
const [user, setUser] = useState(Cookies.get('user'))
const [data, setData] = useState([])
const [loading, setLoading] = useState(true)
const [showDocument, setShowDocument] = useState(false)
const [docId, setDocId] = useState('')
const [jobName, setJobName] = useState('')
const [currentDoc, setCurrentDoc] = useState([{job:'', categories:[]}])
const [showDelete, setShowDelete] = useState(false);
const [update, setUpdate] = useState(false)
const [selectOption, setSelectOption] = useState(true);
const [addQuestion, setAddQuestion] = useState(false);
const [addContent, setAddContent] = useState(false);
const [questionsToAddArray, setQuestionsToAddArray] = useState([])
const [openAddContent, setOpenAddContent] = useState(false)
const [questionInput, setQuestionInput] = useState('')
const [categoryInput, setCategoryInput] = useState('')
const [addType,setAddType] = useState('')
const [currentIndex, setCurrentIndex] = useState(0)
const [editorContent, setEditorContent] = useState('');


const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorContent(data);
  };

const handleBack = ()=>{

    setShowDocument(false)
    setUpdate(!update)
    }


const editDoc = (id, content,name)=>{
    console.log(data)
setEditorContent(content)
setJobName(name)
setDocId(id)
setShowDocument(true)
console.log(currentDoc)
} 

const deleteIQ = async (id)=>{
    const check = window.confirm('Are you sure you would like to do this?');

    if(check){
        
    const resp = await axios.post(API+ 'interview-question-writer/delete-iq',{email:user, iqId: id})
    
    if(resp === 'error'){
        window.alert('Something went wrong when loading user data. Please try again.')
    } else {
        console.log(resp.data)
       setUpdate(!update)
     
        setLoading(false)
    }

}
}


const duplicateIQ = async (job, content)=>{

    const addName = window.prompt('Please enter a different name for this document:');

    if(addName){
    if(addName !== job || addName > 0){

        let newObject = {
            id: uuidv4(),
            jobName: addName,
            dateCreated: new Date(),
            content: content
        }
        const resp = await axios.post(API+ 'interview-question-writer/add-iq',{email:user, object: newObject})

        if(resp === 'error'){
            window.alert('Something went wrong when loading user data. Please try again.')
        } else {
            console.log(resp.data)
            setUpdate(!update)
         
        }
    }
}
}

const changeDocName = async (id, job)=>{

    const addName = window.prompt('Please enter a different name for this document:');
if(addName){
    

    if(addName !== job || addName > 0){

        
        const resp = await axios.post(API+ 'interview-question-writer/change-iq-name',{email:user, id:id, name: addName})

        if(resp === 'error'){
            window.alert('Something went wrong when loading user data. Please try again.')
        } else {
            console.log(resp.data)
            setUpdate(!update)
         
        }
    }

}
}
useEffect(()=>{

    const getData = async ()=>{
            const resp = await axios.post(API+ 'get-data',{email:user})

            if(resp === 'error'){
                window.alert('Something went wrong when loading user data. Please try again.')
            } else {
                console.log(resp.data)
                if(resp.data.length === 0 ){
                    setData([])
                } else {
                    setData(resp.data[0].savedQuestionairres)

                }
             
                setLoading(false)
            }
    }
    getData()
},[update])
if(loading){
    return (
        <div style={{textAlign:'center'}}>
            <CircularProgress color="secondary"/>
        </div>
    )
} else {
    if(!showDocument){
        
    return (
        <Paper sx={{padding:1, margin:4}}>
             <Table hover>
      <thead>
        <tr>
        
          <th><Typography variant="button"><b>Job Name</b></Typography></th>
          <th><Typography variant="button"><b>Date Created</b></Typography></th>
          <th style={{textAlign:'center'}}><Typography variant="button"><b>Actions</b></Typography></th>
        </tr>
      </thead>
      <tbody>
          {data.map(i=>{
            return(
                <tr key={i.id}>
        
          <td ><span >{i.jobName}</span> <ActionButton OnClick={()=>changeDocName(i.id, i.jobName)} Title="Edit Name" Icon={<EditIcon htmlColor="#0098D9"/>}/></td>
          <td style={{paddingTop:'15px'}}>{formatDate(i.dateCreated)}</td>
          <td style={{textAlign:'center'}}>
          <ActionButton OnClick={()=>editDoc(i.id, i.content, i.jobName)} Title="Edit" Icon={<EditIcon htmlColor="#0098D9"/>}/>
          <ActionButton OnClick={()=>duplicateIQ(i.job, i.content)} Title="Duplicate" Icon={<DifferenceIcon htmlColor="#0098D9"/>}/>
          <DownloadDocxIcon htmlContent={editorContent} docName={i.jobName} />
          <ActionButton OnClick={()=>deleteIQ(i.id)} Title="Delete" Icon={<DeleteIcon htmlColor="tomato"/>}/>
          </td>
        </tr>
            )
          })}
      </tbody>
    </Table>
        </Paper>
    )

} else {
    return (
        <div>

            <Box sx={{marginBottom:'10px'}}>
            <Button variant="contained" onClick={handleBack}><ArrowBackIosNewIcon/> <b>BACK</b></Button>

        
            <DownloadDocxButton htmlContent={editorContent} docName={jobName} />
            <Button onClick={()=>{
                 let object = {
                    id:docId,
                    dateCreated: new Date(),
                    jobName: jobName,
                    content: editorContent
                    
                }
                
                UpdateQuestionnaire(object);
                
                
                }} sx={{marginLeft:'10px', float:'right', display:showDelete ? 'none':'inline'}}  variant="contained"><b>SAVE</b></Button>
            </Box>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

        <div style={{ height:'100%'}}>
    <CKEditor
        editor={InlineEditor}
     
        data={editorContent}
        onChange={handleEditorChange}
      />
    
              
    </div>
    <AddContentModal
    Open={openAddContent}
    CloseModal={()=>setOpenAddContent(false)}
    SelectOption={selectOption}
    ClickAddQuestion={()=>{setSelectOption(false); setAddQuestion(true); setAddType('question')}}
    ClickAddCategory={()=>{setSelectOption(false); setAddContent(true); setAddType('category')}}
    ClickQuestionBack={()=>{setSelectOption(true); setAddQuestion(false)}}
    ClickContentBack={()=>{setSelectOption(true); setAddContent(false)}}
    AddQuestion={addQuestion}
    AddCategory={addContent}
    QuestionInputValue={questionInput}
    ChangeQuestionInput={(e)=>{setQuestionInput(e.target.value); console.log(e.target.value)}}
    CatInputValue={categoryInput}
    AddCategoryInputChange={(e)=>{setCategoryInput(e.target.value); console.log(e.target.value)}}
  
    ClickAddQuestionToArray={()=>{
        if(questionInput !== ''){
           
        questionsToAddArray.push(questionInput)
        setQuestionInput('')
        setUpdate(!update)

    }
    }}
    QuestionsToAddArray={questionsToAddArray}
    AddQuestionsToCategory={()=>{
        console.log(currentDoc[0])
        if(addType ==='question') {
            questionsToAddArray.forEach(i=>{

                currentDoc[0].categories[currentIndex].questions.push(i)
            })
        } else {
            let object = {
                category:categoryInput,
                questions: questionsToAddArray
            }
           currentDoc[0].categories.splice(currentIndex + 1, currentIndex, object)

        }

        
        setSelectOption(true); 
        setAddQuestion(false);
        setAddContent(false);
        setOpenAddContent(false);
        setQuestionsToAddArray([])
       setUpdate(!update);
    }}
    />
    </Paper>
    </div>
    )
}
}
    
}