import axios from "axios";
import Cookies from "js-cookie";

const localhost = 'http://localhost:3001/'
const api = 'https://hdhr-apps-api.herokuapp.com/'

export const API = api;
export const SaveQuestionnaire = (object)=>{
    console.log(object)
    if(Cookies.get('user')){

    
const email = Cookies.get('user')
   let  resp = axios.post(API+'interview-question-writer/save-interview-questions', {email:email, object});

if(resp === "error"){
    window.alert('Something went wrong. Please try again.')
} else {
    window.alert('Saved!')
}
    } else {
        window.alert('Please Login.')
    }

}


export const UpdateQuestionnaire = (object)=>{
    console.log(object)
    if(Cookies.get('user')){

    
const email = Cookies.get('user')
   let  resp = axios.post(API+'interview-question-writer/update-iq', {email:email, object});

if(resp === "error"){
    window.alert('Something went wrong. Please try again.')
} else {
    window.alert('Saved Changes!')
}
    } else {
        window.alert('Please Login.')
    }

}