

export function formatDate(inputDate) {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
        // Months use 0 index.
        return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
    }
}

export function separateArray(array) {
    const length = array.length;
    const chunkSize = 4; // Number of items per chunk
  
    const numChunks = Math.ceil(length / chunkSize); // Calculate the number of chunks
  
    const chunks = [];
    for (let i = 0; i < numChunks; i++) {
      const start = i * chunkSize;
      const end = start + chunkSize;
      const chunk = array.slice(start, end);
      chunks.push(chunk);
    }
  
    return chunks;
  }