import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EditIcon from '@mui/icons-material/Edit';
import NotificationsIcon from '@mui/icons-material/Notifications';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import logo from '../assets/iqw_logo.png'
import hdhrIcon from '../assets/hdhr-icon.png'
import ArticleIcon from '@mui/icons-material/Article';
import { NavListItem } from '../components/NavListItem';
import { Writer } from './Writer';
import { Button } from '@mui/material';
import {SavedDocuments} from './SavedDocuments';
import { ActionButton } from '../components/ActionButton';
import DashboardIcon from '@mui/icons-material/Dashboard';

function Copyright(props) {
  return (
    <div style={{textAlign:'center', marginTop:'25px'}}>
      <img src={hdhrIcon} width="75px"/>
      <br></br>
    <Typography variant="caption" color="text.secondary" align="center" {...props}>
     
      {'Copyright © '}
      <Link color="inherit" href="https://helpdeskforhr.com/">
        HelpDesk for HR
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
    </div>
  );
}

const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

function DashboardContent() {
  const [currentPage, setCurrentPage] = React.useState(<Writer Action={()=>{setCurrentPage(<SavedDocuments/>)}}/>)
  const [openTT, setOpenTT] = React.useState(false);

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleTooltipClose = () => {
    setOpenTT(false);
  };

  const handleTooltipOpen = () => {
    setOpenTT(true);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
               
            backgroundColor:'#9755EA',
       
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              
            </Typography>
            <a href='https://helpdeskforhr.com/member-dashboard/'><ActionButton  Title='Return to HelpDesk for HR Dashboard' Icon={<DashboardIcon htmlColor='white'/>} /></a>
            <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={openTT}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={<Typography>The newly built interview question writer is built with smart technologies, meaning it will generate questions for the specific job based on recent data. While this application is live it is still in beta.. Please report any bugs you come across to support@helpdeskforhr.com.</Typography>}
              >
                            <IconButton onClick={handleTooltipOpen}><InfoOutlinedIcon htmlColor='white'/></IconButton>

              </Tooltip>
            </div>
          </ClickAwayListener>
          </Toolbar>
        </AppBar>
        <Drawer  PaperProps={{
          sx:{
            backgroundImage:'linear-gradient(#9755EA, #7E5FA4)'
          }
        }} variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton sx={{color:'white'}} onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <div style={{textAlign:'center', marginBottom:'40px', display:open ?'block':'none'}}>
          <img src={logo} width="200px"/>
          </div>
          <Divider />
          <List sx={{border:'none', paddingTop:0}} component="nav">
          <NavListItem Click={()=>{setCurrentPage(<Writer Action={()=>{setCurrentPage(<SavedDocuments/>)}}/>)}} StyleOpen={open} Title="Interview Question Writer"   Icon={<EditIcon/>}/>
          <NavListItem Click={()=>{setCurrentPage(<SavedDocuments/>)}} StyleOpen={open} Title="Saved Questionnaires"   Icon={<ArticleIcon/>}/>

            </List>

        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
             
              {/* Recent Orders */}
              <Grid item xs={12}>
               {currentPage}
                
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Home() {
  return <DashboardContent />;
}