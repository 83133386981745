import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import AddIcon from '@mui/icons-material/Add';

import '../assets/css/style.css';
import { Form, InputGroup } from 'react-bootstrap';
import { Tooltip } from '@mui/material';
const style = {
  position: 'absolute',
  textAlign:'center',
  top: '50%',
  left: '50%',
  height:'auto',
  transform: 'translate(-50%, -50%)',
  maxWidth:600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AddContentModal  (props) {
  
  
  return (
    <div>
     
      <Modal
        open={props.Open}
        onClose={props.CloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{display:props.SelectOption ? 'block':'none'}}>
          <Typography sx={{fontSize:'18px'}} variant='button'><b>Add Content</b></Typography>
           <Typography variant='subtitle2'>Using this feature can add your own custom questions and categories.</Typography>
   
       
        <Button variant='contained' className="standard-button mt-3" onClick={props.ClickAddQuestion}><b>Add Question To Current Category</b></Button>
       
       <br></br>
        <Button variant='contained' className="standard-button mt-3" onClick={props.ClickAddCategory}><b>Add Custom Category</b></Button>
       </Box>
       
       <Box sx={{display:props.AddQuestion ? 'block':'none', textAlign:'start'}}>
        <Button sx={{padding:0.5}} variant='contained' onClick={props.ClickQuestionBack}>Back</Button>
        
        <Typography sx={{fontSize:'16px', float:'right'}} variant='button'><b>Add Question</b></Typography>
        <br></br>
        <p></p>
        <Typography sx={{fontSize:'14px', marginTop:'10px !important'}} variant='caption'>Type your question in the input below. It will be added to the current category. You can use the "+" to add another question. When you are done click the "Add Questions" buttons.</Typography>
        <InputGroup className="mb-3 mt-3">
        <Form.Control
        value={props.QuestionInputValue}
        style={{width:'300px'}}
          placeholder="Type your question here"
         onChange={props.ChangeQuestionInput}
          aria-describedby="basic-addon2"
        />
        <Tooltip title="Add another question">
        <Button onClick={props.ClickAddQuestionToArray} variant="contained" id="button-addon2">
          <AddIcon/>
        </Button>
        </Tooltip>
      </InputGroup>

  {props.QuestionsToAddArray.map(i=>{
      return (<li>{i}</li>)
    })}
      <Box  sx={{textAlign:'end'}}>

  
     
        <Button onClick={props.AddQuestionsToCategory} variant='contained'>Add Questions</Button>
      </Box>
       </Box>

       <Box sx={{display:props.AddCategory ? 'block':'none', textAlign:'start'}}>
        <Button sx={{padding:0.5}} variant='contained' onClick={props.ClickContentBack}>Back</Button>        
        <Typography sx={{fontSize:'16px', float:'right'}} variant='button'><b>Add Question</b></Typography>
        <br></br>
        <p></p>
        <Typography sx={{fontSize:'14px', marginTop:'10px !important'}} variant='caption'>Type your category title and questions in the inputs below. You can use the <b>"+"</b> to add another question. When you are done click the <b>"Add Custom Section"</b> buttons.</Typography>
       <br></br>
       <br></br>
       <Form.Label><b>Category</b></Form.Label>
        <Form.Control 
        value={props.CatInputName}
        onChange={props.AddCategoryInputChange}
        placeholder='Add Category Title'
        />
            <br></br>
       <Form.Label><b>Add Questions</b></Form.Label>
        <InputGroup className="mb-3 mt-3">
        <Form.Control
        value={props.QuestionInputValue}
        style={{width:'300px'}}
          placeholder="Type your question here"
         onChange={props.ChangeQuestionInput}
          aria-describedby="basic-addon2"
        />
        <br></br>
        <Tooltip title="Add another question">
        <Button onClick={props.ClickAddQuestionToArray} variant="contained" id="button-addon2">
          <b>
          <AddIcon/>
          </b>
        </Button>
        </Tooltip>
      </InputGroup>

  {props.QuestionsToAddArray.map(i=>{
      return (<li>{i}</li>)
    })}
      <Box  sx={{textAlign:'end'}}>

  
     
        <Button onClick={props.AddQuestionsToCategory} variant='contained'><b>Add Custom Section</b></Button>
      </Box>
       </Box>
       </Box>

      </Modal>
    </div>
  );
}