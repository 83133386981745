
import React, {useState, useEffect} from "react";
import { Badge, Form, Stack } from "react-bootstrap";
import Cookies from "js-cookie";
import { ClickableChip } from "../components/ClickableChip";
import { Box, Button, Divider, IconButton, Paper, TextField, Typography, Modal } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Circle } from "../components/Circle";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingModal from "../components/LoadingModal";
import { GenerateDocument } from "../classes/GenerateDocx";
import DownloadDocxButton from "../components/DownloadDocx";
import { API, SaveQuestionnaire } from "../classes/config/controller";
import { v4 as uuidv4 } from 'uuid';
import { ActionButton } from "../components/ActionButton";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import '../assets/css/style.css'
import AddContentModal from "../components/AddContentModal";
import axios from "axios";
import { separateArray } from "../classes/GlobalFunctions";

export const Writer = (props)=>{
const [user, setUser] = useState(Cookies.get('user'))
const [content, setContent] = useState('')
const [loading, setLoading] = useState(true)
const [catArray, setCatArray] = useState([])
const [questions, setQuestions] = useState([])
const [number, setNumber] = useState(0)
const [job, setJob] = useState('')
const [update, setUpdate] = useState(false)
const [openModal, setOpenModal] = useState(false);
const [showDelete, setShowDelete] = useState(false);
const [selectOption, setSelectOption] = useState(true);
const [addQuestion, setAddQuestion] = useState(false);
const [addContent, setAddContent] = useState(false);
const [questionsToAddArray, setQuestionsToAddArray] = useState([])
const [openAddContent, setOpenAddContent] = useState(false)
const [questionInput, setQuestionInput] = useState('')
const [categoryInput, setCategoryInput] = useState('')
const [jobPostGenieData, setJobPostGenieData] = useState([])
const[newJobTitleInput, setNewJobTitleInput] = useState(false)
const[JobTitle, setJobTitle] = useState('')
const [addType,setAddType] = useState('')
const [currentIndex, setCurrentIndex] = useState(0)
const categories = ['Adaptability','Ambition', 'Building Relationships', 'Caution', 'Communication', 'Conflict Management', 'Creativity', 'Customer Orientation','Decision Making', 'Delegation', 'Detail Orientation', 'Employee Development', 'Initiative', 'Integrity','Judgement', 'Leadership', 'Leading Change', 'Listening', 'Motivating Others', 'Negotiating', 'Organizational Awareness', 'Performance Management', 'Persuasion', 'Planning', 'Organizing', 'Presentations', 'Prioritization','Project Management', 'Problem Solving', 'Resilience', 'Sales', 'Self Improvement', 'Setting Goals', 'Strategic Thinking', 'Stress Management','Teamwork', 'Time Management','Values Diversity'] 
const [editorContent, setEditorContent] = useState('');
const [searchType, setSearchType] = useState('')


useEffect(()=>{

    const getData = async ()=>{
            const resp = await axios.post(API+ 'get-data',{email:user})

            if(resp === 'error'){
                window.alert('Something went wrong when loading user data. Please try again.')
            } else {
                console.log(resp.data[0].jobPostings)
                setJobPostGenieData(resp.data[0].jobPostings)
             console.log(jobPostGenieData)
               
            }
    }
    getData()
},[update])
const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorContent(data);
  };

const AddRemoveCategory = (a)=>{

    if(catArray.includes(a)){
        let arr = catArray.filter(i=>{
            return (i !== a)
        })

        setCatArray(arr)
    } else {
        catArray.push(a)
    }
    console.log(catArray)

}
const handleBack = ()=>{
const check = window.confirm('If you choose to go back your questionnaire will not be saved. Are you sure you would like to do this?')
if(check){
 setSearchType('');
 setNewJobTitleInput(false)  
 setJobTitle('') 
setLoading(true);
setCatArray([]);
setNumber(0);
setJob('');

}
}

const createQuestionairre = ()=>{
console.log(job);
console.log(number);
console.log(catArray)
   setOpenModal(true)
        const getData = async ()=>{
         
            let htmlArr = [`<h4><b>Position: ${searchType === 'title' ? job : JobTitle}</b></h4>`]
             
            let dividedArray = separateArray(catArray)
          console.log(dividedArray)
           
            console.log(number)
            
            const prompt = `create a Javascript array of a interview questions for a ${job} using these categories ${JSON.stringify(catArray)} Please write ${JSON.stringify(number)} questions per category. Structure the array with a 'category' property with the category and a 'questions' property with an array of questions for that category. Then write it as a JSON string array and dont include the variable name. do not add any '\n'`
            
            dividedArray.map(async (i)=>{
                const newPrompt = `
            Act as an HR professional. Write interview questions for an ${job} using these categories: ${JSON.stringify(i)}

            Here is a an example of how the html should be returned: 

            <p style="font-size:18px;"><b>(category name)</b></p>
            <ul>
            <li>(Questions)</li>
            ...create ${number} questions per category
            </ul>
            

            `

            const postPrompt = `
            Act as an HR professional. Write interview questions for this job post: ${job} 
            
            use these categories: ${JSON.stringify(i)}

            Here is a an example of how the html should be returned: 

            <p style="font-size:18px;"><b>(category name)</b></p>
            <ul>
            <li>(Questions)</li>
            ...create ${number} questions per category
            </ul>
            

            `
                const response = await axios.post(API+'api/create-data-iqw',{request: searchType ==='title'? newPrompt : postPrompt})
                if(response != 'error'){
                   console.log(response.data)
           
                     
                        let objects;
                        let jsonString = JSON.stringify(response.data)
                        try {
                         htmlArr.push(response.data)

                         if(htmlArr.length === dividedArray.length + 1){
                            setEditorContent(htmlArr.join(''))
                            setOpenModal(false)
                            setLoading(false)
                         }

                        } catch (error) {
                            setOpenModal(false)
                        console.error('Error parsing JSON response:', error);
                        alert('Something went wrong please try again.')
                        return;
                        }
                      
                    //     let arr = JSON.parse(response.text)
    
                       
                
                      
    
                     }
            })
       
                }
                getData()
    
    
}
if(loading) {
    return (
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

        <div style={{padding:'20px'}}>

            <Box>

            <Typography variant="button" sx={{fontSize:'16px'}}><b><Circle Title="1" Color="#9755EA"/>&nbsp; Job title:</b></Typography><br/>
            <Typography sx={{marginTop:'10px'}} variant="subtitle2">Select whether you would like to use a job post you have created using the job post genie or if you would like to generate a new title.</Typography>
         <br></br>
         <Form.Select onChange={(e)=>{
            if(e.target.value === 'ANJ'){
                setNewJobTitleInput(true)
                setSearchType('title')
            
            } 
            else if(e.target.value === '---') {
                 setSearchType('')
                 setNewJobTitleInput(false)
                 
            }
            else{
                setNewJobTitleInput(false)
                setJob(e.target.value)
                let index = e.nativeEvent.target.selectedIndex
                console.log(e.nativeEvent.target[index].text)
                 setJobTitle(e.nativeEvent.target[index].text)
                setSearchType('context')
            }
         }}>
            <option value="---">---</option>
            <option value="ANJ">ADD NEW JOB TITLE</option>
            <optgroup label="Job Post Genie">
                 {jobPostGenieData.map(i=>{
                    return <option value={JSON.stringify(i.content)}>{i.jobName}</option>
                 })}
            </optgroup>
         </Form.Select>
         <div style={{display:newJobTitleInput ? 'block':'none', marginTop:'10px'}}>
         <Typography sx={{marginTop:'10px'}} variant="subtitle2">Please enter the job title you would like questions for.</Typography>

            <Form.Control 
            placeholder="Job Title"
            onChange={(e)=> setJob(e.target.value)} 
            />
            </div>
            </Box>
            <hr></hr>
            <Typography variant="button" sx={{fontSize:'16px'}}><b><Circle Title="2" Color="#9755EA"/>&nbsp;  CATEGORIES:</b></Typography><br/>

            <Typography sx={{marginTop:'10px'}} variant="subtitle2">Select the categories you would like to create questions for.  <b>NOTE:</b> You can choose up to 8 categories.</Typography>
            <br></br>
            <Box  direction="row" sx={{textAlign:'center'}} spacing={1}>
             {categories.map((i,index)=>{
               return (
                <ClickableChip
                Key={index}
                 Label={i}
                 AddClick={()=>AddRemoveCategory(i)}/>

               )
             })}
            </Box>
            <hr></hr>
           
            <Box >
            <Typography variant="button" sx={{fontSize:'16px'}}><b><Circle Title="3" Color="#9755EA"/>&nbsp; Number of Questions:</b></Typography><br/>
            <Typography sx={{marginTop:'10px'}} variant="subtitle2">Select the amount of questions for each category you would like to create. After the questionnaire is create you will be able to delete unwanted questions. NOTE: You can create a up to 5 questions per category.</Typography>

            <br></br>
           <Box  direction="row" spacing={1}>
           <IconButton sx={{marginRight:'15px'}} iconStyle={{width:60, height:60}} onClick={()=>setNumber(number - 1)} ><RemoveIcon /></IconButton>
           <Typography style={{display:'inline', fontSize:'25px'}}><b>{number}</b></Typography>
            <IconButton sx={{marginLeft:'15px'}} onClick={number < 5 ?()=>setNumber(number + 1): ()=> alert('Five questions per category is the max ammount of questions that can be generated.')}><AddIcon/></IconButton>
            
            </Box>
            </Box>
            <br></br>
            <hr></hr>
            <div style={{textAlign:'right'}}>
            <Button sx={{marginTop:1, backgroundColor:'#9755EA'}} variant="contained" onClick={searchType === '' || job === '' || number === 0 || catArray.length < 1 ?()=>{ 
                console.log(job.length);
                let dividedArray = separateArray(catArray)
                console.log(dividedArray)
                window.alert('Please make sure you have filled in the necessary information and that your job title makes sense. You may also have selected more than 8 categories.') }: createQuestionairre}><b>Create Questionnaire</b></Button>
            </div> 
          <LoadingModal
          Open={openModal}
         
          />
       
        </div>
        </Paper>
    )
} else {
    
    return (
        <div>

            <Box sx={{marginBottom:'10px'}}>
            <Button variant="contained" onClick={handleBack}><ArrowBackIosNewIcon/> <b>BACK</b></Button>

            <Button  sx={{marginLeft:'10px', float:'right'}} onClick={()=>setShowDelete(!showDelete)} variant="contained"><b>{showDelete ? 'SAVE CHANGES':'EDIT'}</b></Button>
            <DownloadDocxButton htmlContent={editorContent} docName={job} />
            <Button onClick={()=>{
                 let object = {
                    id:uuidv4(),
                    dateCreated: new Date(),
                    jobName: searchType === 'title'? job: JobTitle,
                    content: editorContent
                }
                SaveQuestionnaire(object);
                props.Action()
                
                }} sx={{marginLeft:'10px', float:'right', display:showDelete ? 'none':'inline'}}  variant="contained"><b>SAVE</b></Button>
            </Box>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

    <div style={{ height:'100%'}}>
    <div style={{ height:'100%'}}>
    <CKEditor
        editor={InlineEditor}
     
        data={editorContent}
        onChange={handleEditorChange}
      />
    
              
    </div>

              
    </div>
    <AddContentModal
    Open={openAddContent}
    CloseModal={()=>setOpenAddContent(false)}
    SelectOption={selectOption}
    ClickAddQuestion={()=>{setSelectOption(false); setAddQuestion(true); setAddType('question')}}
    ClickAddCategory={()=>{setSelectOption(false); setAddContent(true); setAddType('category')}}
    ClickQuestionBack={()=>{setSelectOption(true); setAddQuestion(false)}}
    ClickContentBack={()=>{setSelectOption(true); setAddContent(false)}}
    AddQuestion={addQuestion}
    AddCategory={addContent}
    QuestionInputValue={questionInput}
    ChangeQuestionInput={(e)=>{setQuestionInput(e.target.value); console.log(e.target.value)}}
    CatInputValue={categoryInput}
    AddCategoryInputChange={(e)=>{setCategoryInput(e.target.value); console.log(e.target.value)}}
  
    ClickAddQuestionToArray={()=>{
        if(questionInput !== ''){
           
        questionsToAddArray.push(questionInput)
        setQuestionInput('')
        setUpdate(!update)

    }
    }}
    QuestionsToAddArray={questionsToAddArray}
    AddQuestionsToCategory={()=>{
        if(addType ==='question') {
            questionsToAddArray.forEach(i=>{

                questions[currentIndex].questions.push(i)
            })
        } else {
            let object = {
                category:categoryInput,
                questions: questionsToAddArray
            }
           questions.splice(currentIndex + 1, currentIndex, object)

        }

        
        setSelectOption(true); 
        setAddQuestion(false);
        setAddContent(false);
        setOpenAddContent(false);
        setQuestionsToAddArray([])
       setUpdate(!update);
    }}
    />
    </Paper>
    </div>
)

}
}