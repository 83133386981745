
import React, {useState, useEffect} from "react";
import { Stack } from "react-bootstrap";
import Chip from '@mui/material/Chip';

export const ClickableChip = (props)=>{
const [variant, setVariant] = useState('outlined')
const handleChange = (a)=>{
   if(variant === 'outlined'){
    setVariant('')
    props.AddClick(a)
   } else {
    setVariant('outlined')
    props.AddClick(a)
 }
}

    return (
        <Chip style={{margin:'3px', backgroundColor: variant === 'outlined'? '#9755EA33':'#9755EA88'}} key={props.Key} label={props.Label} variant={variant} onClick={handleChange} />

    )
}